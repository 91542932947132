$.lui("comp",".comp_idf_test", function(selector) {
    const scoreInputs = {}
    selector.find('[data-score-input]').each(function () {
        const el = $(this)[0]

        scoreInputs[el.dataset.scoreInput] = el
    })

    selector.on('click','[data-next]', function (e) {
        e.preventDefault()

        const question = $(this).closest('.elm_comp_question')
        const options = question.find('input[type="radio"]')

        if(options.length > 0) {
            let error = true
            options.each(function () {
                const input = $(this)[0];
                if(input.checked) {
                    error = false
                }
            })

            if(error) {
                question.addClass('state--invalid')
            } else {
                question.removeClass('state--active', 'state--invalid')
                question.next('.elm_comp_question').addClass('state--active')
            }
        } else {
            question.removeClass('state--active')
            question.next('.elm_comp_question').addClass('state--active')
        }
    })

    selector.on('click','[data-prev]', function (e) {
        e.preventDefault()

        const question = $(this).closest('.elm_comp_question')

        question.removeClass('state--active')

        question.prev('.elm_comp_question').addClass('state--active')
    })

    selector.on('click','[data-bmi-calc]', function (e) {
        e.preventDefault()

        const question = $(this).closest('.elm_comp_question')
        const weightElm = question.find('#bmi_weight')
        const heightElm = question.find('#bmi_height')

        weightElm.closest('.part_ui_input').removeClass('state--invalid')
        heightElm.closest('.part_ui_input').removeClass('state--invalid')

        const heightValue = parseFloat(heightElm[0].value.replace(',','.'))

        const weight = parseFloat(weightElm[0].value)
        const height = heightValue > 3 ? heightValue / 100 : heightValue
        const bmi = parseFloat((weight / Math.pow(height, 2)).toFixed(1))

        console.log(isNaN(bmi))

        if(isNaN(bmi)) {
            weightElm.closest('.part_ui_input').addClass('state--invalid')
            heightElm.closest('.part_ui_input').addClass('state--invalid')
        } else {
            question.find('[data-bmi-result]').text(`Vaše BMI je ${bmi}`)

            if(bmi < 25) {
                question.find('[data-score="0"]').prop('checked', true).change()
            } else if (bmi < 30) {
                question.find('[data-score="1"]').prop('checked', true).change()
            } else {
                question.find('[data-score="3"]').prop('checked', true).change()
            }
        }
    })

    selector.on('change','[data-score]', function () {
        const input = $(this)[0]
        const question = $(this).closest('.elm_comp_question')

        question.removeClass('state--invalid')

        if(input.checked) {
            scoreInputs[input.name].value = input.dataset.score
        }
    })
});
