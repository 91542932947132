$.fn.lib_flickity = function () {
    let selector = $(this);
    if (!html.hasClass("ie9")) {
        $.importScript(cdnjs.flickity, function() {
            cssLoaded(function () {
                selector.each(function(){
                    let elm = $(this);
                    let options = elm.data("lib-flickity");
                    let items = elm.children().length;

                    if(items >= options["minItems"]) {
                        let slider = $(this).flickity({
                            cellAlign: (typeof options["cellAlign"] !== "undefined") ? options["cellAlign"] : "left",
                            groupCells: (typeof options["groupCells"] !== "undefined") ? options["groupCells"] : false,
                            setGallerySize: true,
                            autoPlay: (typeof options["autoPlay"] !== "undefined") ? options["autoPlay"] : false,
                            pageDots: true,
                            wrapAround: (typeof options["wrapAround"] !== "undefined") ? options["wrapAround"] : true,
                            contain: true,
                            adaptiveHeight: false,
                            pauseAutoPlayOnHover: true,
                            prevNextButtons: true,
                            percentPosition: false,
                            watchCSS: (typeof options["watchCSS"] !== "undefined") ? options["watchCSS"] : false,
                        });

                        if (typeof options["flex"] !== "undefined") {
                            elm.addClass("flickity-flex");

                            win.on("resize",debounce(function () {
                                elm.removeClass("flickity-flex");
                                elm.flickity("resize");
                                elm.addClass("flickity-flex");
                            },250));
                        }
                    }
                });
            });
        });
    }
};
