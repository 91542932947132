doc.on("click", ".part_ui_select select", function () {
    $(this).parent().toggleClass("state--focus");
    $(this).one("blur", function () {
        $(this).parent().removeClass("state--focus");
    });
});

doc.on("change", ".part_ui_select select", function () {
    if ($(this)[0].value === "") {
        $(this).parent().addClass("state--placeholder");
    } else {
        $(this).parent().removeClass("state--placeholder");
    }
});