$.lui("layout","#layout_header", function(selector) {
    let header = selector.find(".wrp_header_body");

    if (! $("#layout_nav").length) {
        selector.after('<nav id="layout_nav" class="ssm-nav"><div class="wrp_nav_head"></div><div class="wrp_nav_content"></div></nav>');

        $.importScript(cdnjs.touchswipe, function() {
            $('.ssm-nav').lui_ssm();
        });

        let layout_nav = $(document).find("#layout_nav"),
            logo = header.find(".elm_header_logo").clone(),
            nav = header.find(".elm_header_nav").clone();

        logo.html(logo.find(".icon"));

        layout_nav.find(".wrp_nav_head").append(logo);
        layout_nav.find(".wrp_nav_content").append(nav);
    }

    function initHeadroom() {
        $.importScript(cdnjs.headroom, function(){
            let headroom = new Headroom(selector[0], {
                offset: 5,
                tolerance : {
                    up : 5,
                    down : 0
                },
                classes: {
                    "initial" : "headroom",
                    "pinned": "headroom--pinned",
                    "unpinned": "headroom--unpinned",
                    "top" : "headroom--top",
                    "notTop" : "headroom--not-top"
                }
            });
            headroom.init();
        });
    }

    if (typeof Object.assign !== 'function') {
        $.importScript("https://polyfill.io/v3/polyfill.min.js?features=Object.assign",function () {
            initHeadroom();
        });
    }
    else {
        initHeadroom();
    }
});

doc.on("input", "[data-search-autocomplete]", function () {
    let header_search = doc.find("#layout_header").find(".wrp_header_search"),
        header_search_results = doc.find("#layout_header").find(".wrp_header_search_results");

    let results_data = header_search_results.find("[data-results]");
    let query = $(this).val();
    results_data.html("");
    header_search.find(".icon--search").addClass("state--loading");

    $.ajax({
        method: 'get',
        dataType: 'json',
        data: {do: 'search', q: query}
    }).done(function (data) {
        results_data.html("");
        header_search_results.fadeIn(300);
        header_search.find(".icon--search").removeClass("state--loading");

        if (typeof data.results === 'undefined') {
           results_data.append(`
                       <div class="col">
                           <div class="part_ui_heading size--small">${data.lng_not_found}</div>
                       </div>
                   `);
            let resultsAll = $('[data-results-all]');
            resultsAll.find('a').hide();
        } else {
            data.results.forEach(function (item) {
                results_data.append(`
                            <div class="col" data-search-item>
                                <a href="${item.url}" class="part_ui_link text--weight-semi">${item.name}</a>
                            </div>
                        `)
            });
            let resultsAll = $('[data-results-all]');
            resultsAll.find('a').show();
            resultsAll.find('span').text(data.total);
            let link = resultsAll.find('a').first();
            link.attr('href', resultsAll.data("results-all")+query);
            resultsAll.show();
        }
    });
});

doc.on("click", "[data-toggle-search]", function () {
    let header_search = doc.find("#layout_header").find(".wrp_header_search"),
        header_search_results = doc.find("#layout_header").find(".wrp_header_search_results");

    header_search.fadeIn(300);
    header_search.find('input[type="text"]').focus();

    html.on("click", function (e) {
        if ($(e.target).closest('#layout_header').length === 0) {
            header_search.fadeOut(300);
            header_search.find('input[type="text"]').val("").blur();
            header_search_results.fadeOut();
            html.off('click');
        }
    });
});

doc.on("click", "[data-close-search]", function (e) {
    let header_search = doc.find("#layout_header").find(".wrp_header_search"),
        header_search_results = doc.find("#layout_header").find(".wrp_header_search_results");

    e.preventDefault();
    header_search.fadeOut(300);
    header_search.find('input[type="text"]').val("").blur();
    header_search_results.fadeOut();
    html.off('click');
});