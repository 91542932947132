$.fn.validateInput = function () {
    if (!$(this).find("input, textarea").filter("[data-no-validate], [readonly]").length) {
        $(this).removeClass("state--invalid state--valid");
        if ($(this).find("input, textarea").val() !== "") {
            if ($(this).find("input, textarea").is(":valid")) {
                $(this).addClass("state--valid");
            } else {
                $(this).addClass("state--invalid");
            }
        }
    }
};

$.lui("lib_input", ".part_ui_input", function (selector) {
    selector.each(function () {
        $(this).validateInput();
    });
});

doc.on("change", ".part_ui_input", function () {
    $(this).validateInput();
});